import { OptionPanelType, d } from "./Splash/utils";
import React, { useCallback, useEffect, useState } from "react";
import {
  getBannerButtonText,
  onCTAClicked,
  onLikeClicked,
  onShareClick,
  openJioApp,
  youtubeSupport,
} from "../utils/useGlances";

import ActivationPopup from "./Splash/ActivationPopup";
import EmptyGlanceCard from "./EmptyGlanceCard";
import GlanceFeed from "gondolin";
import OptionPanel from "./Splash/OptionPanel";
import colors from "../common/colors";
import { getParameterByName } from "../utils/tracking";
import styled from "@emotion/styled";
import { useRouter } from "next/router";

const ActivationKey = "activation";

const ThreeDots = styled.div`
  background: ${colors.white};
  border-radius: 50%;
  height: 5px;
  position: absolute;
  width: 5px;
  margin-top: 2px;
  top: ${d.glanceTopGap};
  right: ${d.glanceSideGap};

  &::after,
  &::before {
    content: " ";
    position: absolute;
    background: ${colors.white};
    border-radius: 50%;
    height: 5px;
    width: 5px;
    left: 0;
    top: -8px;
  }
  &::after {
    top: auto;
    bottom: -8px;
  }
`;

const GlanceListWithHighlights = (props) => {
  const {
    glances = [],
    fromHome = false,
    fromActivation = false,
    pageName = "",
    isSourceApiIntegration = false,
    fromSportsPage = false,
  } = props;
  const [isReactivationCampaign, setReactivationCampaign] = useState(
    fromActivation
  );
  const [pauseAutoPlay, setPauseAutoPlay] = useState(false);

  const [glanceIndex, setGlanceIndex] = useState(0);
  const [optionPanelType, setOptionPanelType] = useState(null);
  const [jioBannerButtonText, setJioBannerButtonText] = useState("Get App");
  const router = useRouter();

  useEffect(() => {
    youtubeSupport();
    setJioBannerButtonText(getBannerButtonText());
    if (!fromActivation) {
      const utmCampaignParam = getParameterByName(
        "utm_campaign",
        window.location.href
      );
      setReactivationCampaign(
        utmCampaignParam?.toLocaleLowerCase() === ActivationKey
      );
    }
  }, [fromActivation, fromHome]);

  const pauseVideo = useCallback(() => setPauseAutoPlay(true));

  const getStoryID = (storyId) => {
    /**
     * url will be appended with the storyID only for reactivation on OEM lockscreen.
     * story id will not be appended to url in any other reactivation campaign.
     */
    router.query.id = `${storyId}`;

    const entries = Object.entries(router.query);

    const queryParam = entries.map((entry, i) => {
      return `${entry[0]} = ${entry[1]}`;
    });

    const formattedQueryParam = queryParam
      .toString()
      .replace(/,/g, "&")
      .replace(/ = /g, "=");

    if (isReactivationCampaign && pageName.length > 0)
      window.history.pushState(null, ``, `/${pageName}?${formattedQueryParam}`);
  };

  const onMenuClick = useCallback(() => {
    setOptionPanelType(OptionPanelType.MENU);
    pauseVideo();
  }, [setOptionPanelType, pauseVideo]);

  const oemDetails = {
    oemName: router.query.utm_source,
    region: router.query.region,
    locale: router.query.language,
  };

  return (
    <>
      {!glances.length ? (
        <EmptyGlanceCard
          isSourceApiIntegration={
            isSourceApiIntegration || isReactivationCampaign
          }
        />
      ) : (
        <>
          <GlanceFeed
            isSDK={false}
            glances={glances}
            onClickShare={onShareClick}
            onClickLike={onLikeClicked}
            onClickCTA={onCTAClicked}
            onChangeGlanceIndex={setGlanceIndex}
            setPauseAutoPlay={pauseAutoPlay}
            disableShare={isSourceApiIntegration}
            setStoryIdInPWA={getStoryID}
            fromSportsPage={fromSportsPage}
            openApp={openJioApp}
            jioBannerButtonText={jioBannerButtonText}
          />
        </>
      )}
      {!isSourceApiIntegration && !fromActivation && (
        <ThreeDots data-testid="three-dots" onClick={onMenuClick} />
      )}
      <OptionPanel
        type={optionPanelType}
        setOptionPanelType={setOptionPanelType}
        onPanelClose={() => setPauseAutoPlay(false)}
      />
      {isReactivationCampaign && (
        <ActivationPopup
          glanceLength={glances.length}
          glanceIndex={glanceIndex}
          pauseAutoPlay={setPauseAutoPlay}
          fromActivation={fromActivation}
          pageName={pageName}
          OEM_DETAILS={oemDetails}
        />
      )}
    </>
  );
};
export default GlanceListWithHighlights;
